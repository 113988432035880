import { Component, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs'; // 更新
import { map, switchMap, take } from 'rxjs/operators'; // 更新

import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore'; // 追加
import firebase from 'firebase';
import * as firebaseFirestore from 'firebase';

import { AlertViewService } from '../services/alert-view.service';
import { User } from '../class/user/user';
import { Password, Session } from '../class/session/session';
import * as moment from 'moment';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { getLangFromComponent } from '../lang/logic';
import { ComponentLabel } from '../lang/dictionary';
import { Manager } from './model';
import { Department } from '../pages/department/department.component';

const lang = getLangFromComponent(ComponentLabel.session)
@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public signupInfo = new Password()

  public session = new Session();
  public sessionSubject = new Subject<Session>(); // 追加
  public sessionState = this.sessionSubject.asObservable(); // 追加

  private previousTokenValue = "";

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private alertViewService: AlertViewService,
    private afs: AngularFirestore,
    public storage: Storage,
    private platform: Platform,
  ) { } // 追加

  passSignUpValues(account: Password): void { // 追加
    this.signupInfo = account;
    this.router.navigate(['/account']);
  }

  // ログイン状況確認
  checkLogin(): void { // 変更
    this.afAuth
      .authState
      .pipe(
        // authの有無でObservableを変更
        switchMap((auth: firebase.User | null) => {
          if (!auth) {
            return of(null);
          } else {

            return this.getUser(auth.uid);
          }
        })
      )
      .subscribe((user: User | null) => {
        if (environment.debug) {
          console.log(user);
          console.log("■■■■■■■■■■checkLogin called !!!");
        }
        this.session.login = (!!user);
        this.session.user = (user) ? user : new User();
        if (environment.debug) {
          console.log(this.session.user);
        }
        this.sessionSubject.next(this.session);


        if (this.session.login) {
          this.setDeviceToken();
        }
      });
  }

  async getManager(): Promise<Manager | null> {
    const auth = await this.afAuth.currentUser;
    const managers = (await firebase.firestore().collection('managers').where('uid', '==', auth.uid).limit(1).get()).docs;
    if(managers.length === 0) {
      return null;
    } else {
      return managers[0].data() as Manager;
    }
  }

  async isAdminDepaertment(): Promise<boolean> {
    const manager = await this.getManager();
    return manager === null;
  }

  async checkCorrectDepartment(department: string, routing: string): Promise<Department | null> {
    const dep = ((await firebase.firestore().collection('departments').where('id', '==', department).limit(1).get()).docs.map(doc => doc.data())[0] ?? null) as Department | null;
    const manager = await this.getManager();

    if(manager === null) {
      // this.router.navigate(['/login']);
      return dep;
    }

    if(!manager.groupId) {
      this.alertViewService.showErrorAlert(lang.show("所属を設定されていないユーザーです。"));
      this.router.navigate(['/login']);
      return null;
    } else if(!manager.groupId && department === 'TOP') {
      return null;
    } if(manager.groupId === dep?.id) {
      return dep;
    } else {
      this.router.navigate([manager.groupId + '/' + routing]);
      return dep;
    }
  }

  setDeviceToken() {
    if (this.platform.is('cordova')) {
      this.storage.get('devicetoken').then((token) => {
        if (token !== this.previousTokenValue) {
          this.previousTokenValue = token;
          if (token.length > 0) {
            this.afs.collection('users').doc(this.session.user.uid).update({
              device_token: token
            })
          }
        }
      })

    }
  }

  async getRouteableDepartments(): Promise<Department[]> {
    const manager = await this.getManager();

    if(manager === null) {
      return [
        { id: '', name: '管理権限' },
        ...(await firebase.firestore().collection('departments').get()).docs.map(doc => doc.data()) as Department[]
      ];
    } else {
      return (await firebase.firestore().collection('departments').where('groupId', '==', manager.groupId).get()).docs.map(doc => doc.data()) as Department[];
    }
  }

  // ログイン状況確認(State)
  checkLoginState(): Observable<Session> {
    return this.afAuth
      .authState
      .pipe(
        map((auth: firebase.User | null) => { // 更新
          // ログイン状態を返り値の有無で判断
          if (environment.debug) {
            console.log(auth);
            console.log("◆◆◆◆◆◆◆checkLoginState called !!!");
          }

          this.session.login = (!!auth);
          return this.session;
        })
      );
  }


  login(account: Password): void { // 変更
    this.alertViewService.showLoading();
    this.afAuth
      .signInWithEmailAndPassword(account.email, account.password)
      .then(auth => {

        //        if (!auth.user.emailVerified) {
        if (false) {
          auth.user.sendEmailVerification().then(() => {
            this.afAuth.signOut();
            this.alertViewService.showErrorAlert('メールアドレスの確認を完了していません。確認メールを再送いたしました。');
          })

          return Promise.reject('');
        } else {
          this.session.login = true;
          this.sessionSubject.next(this.session);
          return this.router.navigate(['/']);
        }
      })
      .then(() => {
        this.alertViewService.dismiss();
        this.alertViewService.notify(lang.show('ログインしました'))
      })
      .catch(err => {
        console.log(err);
        this.alertViewService.notify(lang.show('ログインに失敗しました。Emailパスワードをお確かめください'));
      });
  }


  resetPwd(email) {
    return new Promise((resolve) => {
      this.afAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          this.alertViewService.showGoodAlert(lang.show('パスワードリセットのためのリンクをEmailアドレスに送信いたしました'));
          resolve(true);

        })
        .catch(err => {
          console.log(err);
          this.alertViewService.notify(lang.show('パスワードリセットに失敗しました。Emailをお確かめください'));
          resolve(false);
        });
    })
  }


  logout(): void {// 変更

    this.logoutInside();

  }

  logoutInside() {
    this.afAuth
      .signOut()
      .then(() => {
        this.sessionSubject.next(this.session.reset());
        return this.router.navigate(['/login']);
      }).then(() => {
        this.alertViewService.notify(lang.show('ログアウトしました。'));
      })
      .catch(err => {
        console.log(err);
        this.alertViewService.notify(lang.show('ログアウトに失敗しました。'));
        return this.router.navigate(['/login']);
      });
  }

  signup(account: Password): void { // 追加
    this.alertViewService.showLoading();

    this.afs.collection<User>('pending-invite').doc(account.email)
      .get().toPromise().then((inviteInfo) => {

        this.afAuth
          .createUserWithEmailAndPassword(account.email, account.password) // アカウント作成
          .then(auth => {

            if (inviteInfo.exists) {

              const data = inviteInfo.data();

              this.afs.collection('patients').doc(data["patient"]).update({
                members: firebaseFirestore.default.firestore.FieldValue.arrayUnion(auth.user.uid),
                updated: +moment()
              }).then(() => {
                this.createUserAndSendEmail(auth, account);
              })
            } else {
              this.createUserAndSendEmail(auth, account);
            }


          }).then(() => {
            this.alertViewService.dismiss();
            this.alertViewService.showGoodAlert(lang.show('確認メールを送信しました。')).then((ret) => {
              account.reset();
            })
            this.router.navigate(['/account-three']);
          }).catch(err => {
            console.log(err);
            this.alertViewService.dismiss();
            this.alertViewService.showErrorAlert(lang.show('アカウントの作成に失敗しました。時間をおいて再度お試しください。'));
          });
      });
  }


  private createUserAndSendEmail(auth, account) {
    const user = new User(auth.user.uid, account.name, account.picture, account.email, account.phone, account.clinic_id, account.occupation);
    this.afs.collection('users').doc(user.uid).set(user.deserialize()).then(() => {
      auth.user.sendEmailVerification().then(() => {
        this.afAuth.signOut();
      })
    })
  }

  // ユーザーを取得
  private getUser(uid: string): Observable<any> { // 追加
    return this.afs
      .collection('users')
      .doc(uid)
      .valueChanges()
      .pipe(
        take(1),
        switchMap((user: User) => {
          if (user) {
            return of(user);
          } else {
            return of(null);
          }
        })
      );
  }



}
