

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import Swal from 'sweetalert2'

import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material/table";
import {
  PeriodicElement,
  Transaction,
  Columns,
} from "../../assets/table-data";
import { Observable } from 'rxjs';
import { TypeTabName, _Alerts, getAlertDate, getMonthShort } from '../alert-summary/alert-summary.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { HirouRepository } from 'src/app/repository/HirouRepository';
import { dateEqual } from 'src/app/repository/DateHelper';
import ConditionRepository from 'src/app/repository/ConditionRepository';
import DeviceRepository from 'src/app/repository/DeviceRepository';
import { addDays } from 'date-fns';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { DepartmentRepository } from 'src/app/repository/DepartmentRepository';
import { SessionService } from 'src/app/service/session.service';
import { Department } from '../department/department.component';

export interface AlertItem {
  name: string;
  managerName: string;
  deviceNumber: string;
  time: string;
  category: string;
}

const _Pending = 'pending';

const addDay = (date: Date, day: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + day);
  return newDate;
}

type Element = PeriodicElement | Transaction;
@Component({
  selector: 'app-condition-list',
  templateUrl: './condition-list.component.html',
  styleUrls: ['./condition-list.component.css']
})


export class ConditionListComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.conditionlist);
  public dataSource: MatTableDataSource<Element> =
    new MatTableDataSource<Element>();

  public columns: Columns[] = [];
  public rowColumns: string[] = [];
  public devices: any[] = [];
  public sortedDevices: any[] = [];
  public users: any[];

  public _TabNames: TypeTabName[] = ["心拍異常", "熱中症", "疲労"];
  public tabName: TypeTabName = "熱中症";

  public days: Date[] = [];
  public subcate: any[] = [];
  
  pending = _Pending;
  onlyActiveDevice = true;
  numOfItemSlice = 1000000;
  sortHeaderNum = 0;
  public isMaster = false;

  department: Department | null = null;
departmentId = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private session: SessionService
  ) {
    this.departmentId = this.activatedRoute.snapshot.paramMap.get('department');
    this.session.checkCorrectDepartment(this.departmentId, 'condition-list').then(d => {
      this.department = d;
      this.afAuth.currentUser.then(async user => {
        const snapShot = await firebase.default.firestore().collection('managers').where('uid', '==', user.uid).get();
        this.isMaster = snapShot.docs.length === 0;
      });
  
      this.days =  [...Array(7)].map((_, i) => addDays(new Date(), i - 6));
    });
  }

  isTop(): boolean {
    return this.department === null;
  }

  public getDevices(): any[] {
    return [...this.devices.filter(x => x.timestamp).sort((a, b) => b['timestamp'].getTime() - a['timestamp'].getTime())] //[...this.devices.sort((a, b) => this.getAlertsSum(a, this.tabName) >= this.getAlertsSum(b, this.tabName) ? -1 : 1)];
  }

  public getShortDate(date: Date): string {
    return this.lang.showDateMD(`${date.getMonth() + 1}/${date.getDate()}`);
  }

  public getWeekDay(date: Date): string {
    return this.lang.showArray(['日', '月', '火', '水', '木', '金', '土'])[date.getDay()];
  }

  public getWearerName(device: any): string {
    const wearerId = device.wearerId;
    if(!wearerId) { return ''; }

    const wearer = this.subcate.find(user => user.id === wearerId);
    return wearer?.data()?.name ?? '';
  }

  public getAlertLastDate(device: any, date: Date, tab: TypeTabName): string {
    const alertChunks = this.days.map(day => this.getAlertsRaw(device, day, tab));
    let alerts: string[] = [];
    alertChunks.forEach(chunk => alerts = alerts.concat(chunk));
    const alertDates = alerts.map(alert => getAlertDate(alert)).filter(x => x !== null) as Date[];
    if(alertDates.length === 0) { return ''; }

    const lastDate = alertDates.sort((a, b) => b.getTime() - a.getTime())[0];

    return this.lang.showDate(`${lastDate.getFullYear()}/${lastDate.getMonth() + 1}/${lastDate.getDate()}`);
  }

  public getAlertsRaw(device: any, date: Date, tab: TypeTabName): string[] {
    const alertLabels = _Alerts[this._TabNames.indexOf(tab)]; // _Alerts[this._TabNames.indexOf(this.tabName)];
    const alerts = (device.alertsHistory?.filter(alert => alert.indexOf(`${getMonthShort(date)} ${date.getDate() <= 9 ? '0' : ''}${date.getDate()}`) > 0 &&
     alertLabels.some(al => alert.indexOf(al) > -1) && alert.indexOf(`${date.getFullYear()}_`) > 0) ?? []) as string[]; 

     return this.lang.showArray(alerts)
  }

  public getAlerts(device: any, date: Date, tab: TypeTabName): number {
    return this.getAlertsRaw(device, date, tab).length;
  }

  public getAlertsSum(device: any, tab: TypeTabName): number {
    return this.days.map(day => this.getAlerts(device, day, tab)).reduce((a, b) => a + b, 0);
  }

  sliceMacAdress(ma) {
    var ret = ma.replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '')

    return ret.slice(-4);
  }

  setInitValue(myDevices: any) {
    this.devices = myDevices.map(data => {
      data['hirou'] = this.days.map(x => _Pending);
      data['hirouSum'] = _Pending;
      data['conditions'] = this.days.map(x => '-');
      data['timestamp'] = new Date('2000/1/1');
      return data;
    });

    // this.sortedDevices = DeviceRepository.getPriorityDevices(this.devices);
  }

  async readDevices(): Promise<void> {
    const nisshuseiHirou = this.lang.show('日周性疲労')
    const manseiHirou = this.lang.show('慢性疲労')
    const defaultDate = new Date('2000/1/1');

    const todayDateInt = new Date().getFullYear() * 10000 + (new Date().getMonth() + 1) * 100 + new Date().getDate();
    const eightDaysAgo = addDay(new Date(), -8);
    const eightDaysAgoDateInt = eightDaysAgo.getFullYear() * 10000 + (eightDaysAgo.getMonth() + 1) * 100 + eightDaysAgo.getDate();

    await Promise.all(this.devices.map(async doc => {
        let data = doc;

        const meDeviceThen = (action: () => void) => {
          if(data.macAdress.indexOf('1244') >= 0) {
            action();
          }
        };

        data['hirou'] = this.days.map(x => _Pending);
        data['hirouSum'] = _Pending;
        data['conditions'] = this.days.map(x => '-');
        data['timestamp'] = defaultDate;

        const todayHirou = await HirouRepository.getTodayHirou(data.macAdress);
        const sammeries = (await firebase.default.firestore().collection(data.macAdress + '-summary')
              .where('keisokubi', '>=', eightDaysAgoDateInt)
              .orderBy('keisokubi', 'desc')
              .limit(9)
              .get()
        ).docs.map(d => d.data())

        const totals = (await firebase.default.firestore().collection(data.macAdress + '-total')
            .where('time' , '>=', eightDaysAgo)
            .orderBy('time', 'desc')
            .limit(9)
            .get()
        ).docs.map(d => d.data());

        meDeviceThen(() => console.log({totals: totals.map(x => { return { ...x, time: x.time.toDate() } })}));

        const hiroudoMessages = await Promise.all(this.days.map(async day => {
          const nextDay = addDay(day, 1);
          const dateInt = nextDay.getFullYear() * 10000 + (nextDay.getMonth() + 1) * 100 + nextDay.getDate();
          const getDayTPAvg = async (): Promise<number | null> => {

            const hours = dateInt === todayDateInt ? todayHirou.TPHours : sammeries.filter(s => s.keisokubi === dateInt).map(s => s.TPHours)
            if(!hours || hours.length === 0) { return null; }

            const activeHours = hours.filter(hour => hour !== null || hour !== 0);
            return activeHours.length === 0 ? 0 : activeHours.reduce((a, b) => a + b, 0) / activeHours.length;
          };

          const getTPHoursTile25 = () => {
            const baseDay = dateInt === todayDateInt ?  this.days[this.days.length - 2] : day;
            const time1 = addDay(new Date(baseDay.getFullYear(), baseDay.getMonth(), baseDay.getDate(), 0, 0, 0), 1);
            const time2 = addDay(time1, 1);

            const total =  totals.filter(t => t.time.toDate() >= time1 && t.time.toDate() < time2).map(t => t.TPHoursTile25);

            return total.length === 0 ? null : (total[0]?.TPHoursTile25 ?? null) as number | null;
          };

          const dayAvg = await getDayTPAvg();
          const totalAvg = getTPHoursTile25();

          if(!dayAvg || !totalAvg) { return ''; }

          return dayAvg < totalAvg ? nisshuseiHirou : '';
        }));

        const getConditions = () => {
          return this.days.map(d => {
            const total = totals.find(t => dateEqual(addDay(d, 0), t as any));

            return ConditionRepository.getCondition(total?.devLF, total?.devHF);
          });
        }

        
        data['hirouSum'] = hiroudoMessages.filter(x => x !== '').length;
        data['hirou'] = this.days.map(d => HirouRepository.getHirouMessage(d, sammeries as { keisokubi: number, time: any, TPHours: any  }[], totals as { time: any, TPHoursTile25: number | null }[]));
        data['conditions'] = getConditions();
        data['timestamp'] = (await firebase.default.firestore().collection(data.macAdress).orderBy('timestamp', 'desc').limit(1).get())?.docs[0]?.data()?.timestamp?.toDate() ?? new Date('2000/1/1');

        return data;
    }));
    
    this.sortedDevices = DeviceRepository.getPriorityDevices(this.getDevices());
  }

  async initialize(): Promise<void> {
    this.subcate = (await DepartmentRepository.getDepartmentDevices(this.department))
      .filter(x => x.id !== '');

  this.users = (await DepartmentRepository.getDepartmentSubcates(this.department))
    .map(data => {
        if (data.phone === undefined) {
          data.phone = '';
        }
        return data;
    });
  
    const myDevices: any = await DepartmentRepository.getDepartmentDevices(this.department);

    this.setInitValue(myDevices);
    await this.readDevices();

    console.log({ devices: this.sortedDevices })
  }

  ngOnInit(): void {
    this.initialize();
  }
}