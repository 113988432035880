import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import * as firebase from 'firebase/app';
import { addDay } from 'src/app/repository/Tiime';
import { Distinct } from 'src/app/repository/Distinct';
import { DepartmentRepository } from 'src/app/repository/DepartmentRepository';
import { Department } from '../department/department.component';

interface Summary {
  id: string,
  department: string
  incidents: string[]
  occurrences: string[]
}

const defaultSummary: Summary = {
  id: '',
  department: '',
  incidents: [...Array(5)].map(() => '-'),
  occurrences: [...Array(5)].map(() => '-'),
};

const _Alerts: string[] = [
  '心拍',
  '熱中症',
  '疲労',
  '眠気',
  '転倒'
];

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})


export class SummaryComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.devicelist);
  department: Department | null = null;
  departmentId = '';
  yesterday: Summary[] = [defaultSummary];
  lastMonth: Summary[] = [defaultSummary];
  lastYear: Summary[] = [defaultSummary];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private session: SessionService, 
  ) {
    this.departmentId = this.route.snapshot.paramMap.get('department') ?? '';
    session.checkCorrectDepartment(this.departmentId, 'summary').then(d => {
      this.department = d;
      this.initialize();
    });
  }

  isTop(): boolean {
    return this.department === null;
  }

  move(department: Summary) {
    if(!department) return;
    this.router.navigate([`/${department?.id ?? 'TOP'}/summary`]);
  }

  async getAllDepartments(): Promise<Department[]> {
    return !this.department ? [...await DepartmentRepository.readDepartments()] : [this.department];
  }

  async initialize() {
    const departments = await this.getAllDepartments();
    this.yesterday = [];
    this.lastMonth = [];
    this.lastYear = [];

    departments.forEach(async department => {
      const devices = await DepartmentRepository.getDepartmentDevices(department);
      const now = new Date();
      const yesterday = addDay(now, -1);
      const lastMonth = addDay(now, now.getDate() - 2);
      const lastYearToday = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

      this.yesterday.push(this.getIncidentsAndOccurrences(
        department,
        this.getRangeDevices(devices,
          new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()),
          new Date(now.getFullYear(), now.getMonth(), now.getDate()))
      ));

    this.lastMonth.push(this.getIncidentsAndOccurrences(
        department,
        this.getRangeDevices(devices,
          new Date(lastMonth.getFullYear(), lastMonth.getMonth(), lastMonth.getDate()),
          new Date(now.getFullYear(), now.getMonth(), 1))
        ));

      this.lastYear.push(this.getIncidentsAndOccurrences(
        department,
        this.getRangeDevices(devices,
          new Date(lastYearToday.getFullYear(), lastYearToday.getMonth(), 1),
          new Date(lastYearToday.getFullYear(), lastYearToday.getMonth(), lastYearToday.getDate()))
        ));
      });
  }

  getIncidentsAndOccurrences(department: Department, devices: any[]): Summary {
    const incidents = _Alerts.map(alert => Distinct(
      devices.filter(d => d.alertsHistory && d.alertsHistory.some(history => history.split("_")[1].includes(alert)))
      .map(device => device.wearerId)).length
    );

    const occurrences = _Alerts.map(alert => 
      devices.map(d => d.alertsHistory.filter(cu => cu.split("_")[1].includes(alert)).length)
      .reduce((prev, curr) => prev + curr, 0)
    );

    return {
      id: department.id,
      department: department.name,
      incidents,
      occurrences
    };
  }

  getRangeDevices(devices: any[], start: Date, end: Date): any[] {
    return devices.filter(device => {
      const alertsHistory = device.alertsHistory;
      if(!alertsHistory) return false;

      return alertsHistory.some(alert => {
        const [time, category] = alert.split('_');
        const alertDate = new Date(time);
        return alertDate >= start && alertDate < end && _Alerts.some(a => category.includes(a));
      });
    });
  }

  ngOnInit(): void {
  }
}
